import * as React from 'react';
import { Route, IndexRedirect, IndexRoute } from 'react-router';
import { Switch, Router } from 'react-router-dom';

import { ConditionalRoute } from './conditional';

import Storage from './helpers/storage';

import App from './app';
import ProfilePage from './pages/profilePage';
import ApplicationsPage from './pages/applicationsPage';
import ApplicationPage from './pages/applicationPage';
import ApplicationUpdatePage from './pages/applicationUpdatePage';
import ApplicationSubmitPage from './pages/applicationSubmitPage';
import DocumentsPage from './pages/documentsPage';
import FaqsPage from './pages/faqsPage';
import LoannetPage from './pages/loannetPage';
import IntroducersPage from './pages/introducersPage';
import LoginPage from './pages/loginPage';
import ResetPage from './pages/resetPage';
import AccreditationPage from './pages/accreditationPage';
import ServiceAbilityPage from './pages/serviceAbilityPage';
import ComServiceAbilityPage from './pages/comServiceAbilityPage';
import MotorPrequalToolPage from './pages/motorPrequalToolPage';
import RateQuotePage from './pages/rateQuotePage';
import LvrPage from './pages/lvrPage';
import RepaymentsPage from './pages/repaymentsPage';
import VeiCalculatorPage from './pages/veiCalculatorPage';
import PrequalPage from './pages/prequalPage';
import NotFoundPage from './pages/notFoundPage';
import MaintenancePage from './pages/maintenancePage';
import MotorSubmissionPage from './pages/motorSubmissionPage';
import MotorSubmissionCommercialPage from './pages/motorSubmissionCommercialPage';
import LfiProtectPrequalPage from './pages/lfiProtectPrequalPage';
import ResiSubmissionPage from './pages/residentialSubmissionPage';

import Event3rdParty from './helpers/event3rdParty';
import { EmptyNoMobile, EmptyNotAllowed } from './utils';
import { PRODUCTGROUPS } from './constants/types';
import { msalInstance } from './helpers/authConfig';
import { AssetClassesFilter } from './pages/documentsPage/documentTab';

const Moment = require('moment');

const onEnter = (nextState, replace, callback) => {
  const isUsingB2C = Storage.getItem('isUseB2C');
  const token = Storage.getItem('token');
  let isAuthenticated = false;
  try {
    if (isUsingB2C === 'true') {
      isAuthenticated = token !== undefined && token !== '' && token !== null;
    } else {
      const t = JSON.parse(token);
      if (t !== undefined && t !== '' && t !== null) isAuthenticated = true;
    }
  } catch (error) {
    isAuthenticated = false;
  }

  Event3rdParty.pageview(nextState.location.pathname);

  /**
   * Send the introducer to the applications page if they
   * are trying to access the root and send them to the login
   * page if they don't have authentication
   */
  if (nextState.location.pathname == '/' || nextState.location.pathname.substr(nextState.location.pathname.length - 10) == 'index.html') {
    if (isAuthenticated) {
      let redirect = '/applications';
      if (isUsingB2C === true) redirect = '/introducers';
      replace(redirect);
    } else {
      replace('/login');
    }
  }

  /**
   * Send the introducer to the applications page if they
   * are trying to access the login or reset page when they
   * are already logged in
   */
  if (nextState.location.pathname.search('/login') != -1 || nextState.location.pathname.search('/reset') != -1 || nextState.location.pathname.search('/get-accredited') != -1) {
    if (isAuthenticated) {
      let redirect = '/applications';
      if (isUsingB2C === true) redirect = '/introducers';
      replace(redirect);
    }
  }

  /**
   * Send the introducer back to the login page if they
   * are trying to access an authenticated page without
   * having any authentication
   */
  if (nextState.location.pathname.search('/login') == -1
        && nextState.location.pathname.search('/reset') == -1
        && nextState.location.pathname.search('/get-accredited') == -1) {
    if (!isAuthenticated) {
      Storage.removeItem('token');
      Storage.removeItem('cache');

      if (nextState.location.pathname) {
        Storage.setItem('redirect', nextState.location.pathname);
      }

      replace('/login');
    }
  }

  callback();
};


const isNotResidential = (state) => !state.session.details.isResidential;
const isNotSubmission = (state) => !state.session.details.isSubmission;
const isMobile = (state) => state.session.details.isPhoneSized;
const isNotMotor = (state) => !state.session.details.isMotor;
const isNotCaravan = (state) => state.session.details.products.indexOf((p) => p.productGroupId === PRODUCTGROUPS.MOTOR_COSUMER_CARAVAN) !== -1;
const isNotNovatedLease = (state) => state.session.details.products.indexOf((p) => p.productGroupId === PRODUCTGROUPS.MOTOR_NOVATED_LEASE) !== -1;
const isNotCommercialMotor = (state) => state.session.details.products.indexOf((p) => p.productGroupId === PRODUCTGROUPS.MOTOR_COMMERCIAL) !== -1;

const maintenanceFromUtc = '2022-10-29T23:00:00Z';
const maintenanceToUtc = '2022-10-29T23:30:00Z';

export default (
  <Router>
    <Switch>
      <Route
        path="/Opportunities(/:id)"
        onEnter={onEnter}
        component={ConditionalRoute}
        mainComponent={ResiSubmissionPage}
        overrideComponents={[
          {
            condition: isNotResidential,
            component: EmptyNotAllowed,
          },
        ]}
      />
      <Route path="/" component={App} onEnter={onEnter}>

        {Moment().isBetween(maintenanceFromUtc, maintenanceToUtc)
          ? <Route path="*" component={MaintenancePage} />
          : null}

        <Route path="login/:username" component={LoginPage} />
        <Route path="login" component={LoginPage} />

        <Route path="reset/:username" component={ResetPage} />
        <Route path="reset" component={ResetPage} />

        <Route path="get-accredited" component={AccreditationPage} />
        <Route path="get-accredited/:step" component={AccreditationPage} />
        <IndexRedirect to="/applications" />

        <Route path="applications">
          <IndexRoute component={ApplicationsPage} onEnter={onEnter} />
          {/* Residential submission route disabled until UAF completed. */}
          <Route
            path="submit/residential(/:id)"
            onEnter={onEnter}
            component={ConditionalRoute}
            mainComponent={ApplicationSubmitPage}
            overrideComponents={[
              {
                condition: isNotResidential,
                component: EmptyNotAllowed,
              },
              {
                condition: isNotSubmission,
                component: EmptyNotAllowed,
              },
              {
                condition: isMobile,
                component: EmptyNoMobile,
              },
            ]}
          />

          <Route
            path="submit/motor(/:id)"
            component={ConditionalRoute}
            mainComponent={MotorSubmissionPage}
            onEnter={onEnter}
            overrideComponents={[
              {
                condition: isNotMotor,
                component: EmptyNotAllowed,
              },
            ]}
            mainComponentProps={{ productType: 'ConsumerMotor' }}
          />

          <Route
            path="submit/caravan(/:id)"
            component={ConditionalRoute}
            mainComponent={MotorSubmissionPage}
            onEnter={onEnter}
            overrideComponents={[
              {
                condition: isNotMotor,
                component: EmptyNotAllowed,
              },
              {
                condition: isNotCaravan,
                component: EmptyNotAllowed,
              },
            ]}
            mainComponentProps={{ productType: 'ConsumerCaravan' }}
          />

          <Route
            path="submit/commercial(/:id)"
            component={ConditionalRoute}
            mainComponent={MotorSubmissionCommercialPage}
            onEnter={onEnter}
            overrideComponents={[
              {
                condition: isNotMotor,
                component: EmptyNotAllowed,
              },
              {
                condition: isNotCommercialMotor,
                component: EmptyNotAllowed,
              },
            ]}
          />

          <Route
            path="submit/novated(/:id)"
            component={ConditionalRoute}
            mainComponent={MotorSubmissionPage}
            onEnter={onEnter}
            overrideComponents={[
              {
                condition: isNotMotor,
                component: EmptyNotAllowed,
              },
              {
                condition: isNotNovatedLease,
                component: EmptyNotAllowed,
              },
            ]}
            mainComponentProps={{ productType: 'NovatedLease' }}
          />

          <Route path=":applicationID/update/:tab" component={ApplicationUpdatePage} onEnter={onEnter} />
          <Route path=":applicationID/update" component={ApplicationUpdatePage} onEnter={onEnter} />
          <Route path=":applicationID/:tab" component={ApplicationPage} onEnter={onEnter} />
          <Route path=":applicationID" component={ApplicationPage} onEnter={onEnter} />
        </Route>

        <Route path="calculators">
          <IndexRoute component={PrequalPage} onEnter={onEnter} />

          <Route path="prequalification" component={PrequalPage} onEnter={onEnter} />
          <Route path="motor-prequalification" component={(props) => <MotorPrequalToolPage {...props} />} onEnter={onEnter} />
          <Route path="motor-serviceability" component={ServiceAbilityPage} onEnter={onEnter} />
          <Route path="motor-serviceability-standalone" component={ServiceAbilityPage} onEnter={onEnter} />

          <Route
            path="motor-prequalification(/:id)"
            onEnter={onEnter}
            component={ConditionalRoute}
            mainComponent={(props) => <MotorPrequalToolPage {...props} />}
            overrideComponents={[
              {
                condition: isNotMotor,
                component: EmptyNotAllowed,
              },
            ]}
          />

          <Route
            path="serviceability(/:id)"
            onEnter={onEnter}
            component={ConditionalRoute}
            mainComponent={ServiceAbilityPage}
            overrideComponents={[
              {
                condition: isNotResidential,
                component: EmptyNotAllowed,
              },
            ]}
          />

          <Route
            path="commercial-serviceability(/:id)"
            onEnter={onEnter}
            component={ConditionalRoute}
            mainComponent={ComServiceAbilityPage}
            overrideComponents={[
              {
                condition: isNotResidential,
                component: EmptyNotAllowed,
              },
            ]}
          />

          <Route
            path="repayments(/:id)"
            onEnter={onEnter}
            component={ConditionalRoute}
            mainComponent={RepaymentsPage}
            overrideComponents={[
              {
                condition: isNotResidential,
                component: EmptyNotAllowed,
              },
            ]}
          />

          <Route
            path="rate(/:id)"
            onEnter={onEnter}
            component={ConditionalRoute}
            mainComponent={RateQuotePage}
            overrideComponents={[
              {
                condition: isNotResidential,
                component: EmptyNotAllowed,
              },
            ]}
          />

          <Route
            path="lvr(/:id)"
            onEnter={onEnter}
            component={ConditionalRoute}
            mainComponent={LvrPage}
            overrideComponents={[
              {
                condition: isNotResidential,
                component: EmptyNotAllowed,
              },
            ]}
          />
        </Route>

        <Route path="introducers" component={IntroducersPage} onEnter={onEnter} />

        <Route path="documents">
          <IndexRoute component={DocumentsPage} onEnter={onEnter} mainComponentProps={{ filter: AssetClassesFilter.Residential }} />

          <Route path="cd" component={DocumentsPage} onEnter={onEnter} mainComponentProps={{ filter: AssetClassesFilter.Commercial }} />
        </Route>

        <Route path="loannet" component={LoannetPage} onEnter={onEnter} />

        <Route path="profile" component={ProfilePage} onEnter={onEnter} />

        <Route path="faqs" component={FaqsPage} onEnter={onEnter} />

        <Route path="*" component={NotFoundPage} />
      </Route>
    </Switch>
  </Router>
);
