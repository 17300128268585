import * as React from 'react';
import { useHistory } from 'react-router-dom';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'lf-opportunity': any;
    }
  }
}

export interface ResiSubmissionWebComponentProps {
  opportunityID: string;
}
export const ResiSubmissionWebComponent =
  ({ opportunityID }
    : ResiSubmissionWebComponentProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const history = useHistory();

    console.log('opportunityID', opportunityID);

    React.useLayoutEffect(() => {
      const { current } = ref;

      const onRouteChange = (event: CustomEvent) => {
        if (typeof event.detail === 'string' && event.detail.startsWith('/')) {
          history.push(event.detail);
        } else {
          console.error('Invalid route:', event.detail);
        }
      };

      if(current){
        current.addEventListener('routeChange', onRouteChange as EventListener);
        current.setAttribute('opportunity', opportunityID);
      }

      return () => {
        if (current) {
          console.log('removing event listener');
          current.removeEventListener('routeChange', onRouteChange as EventListener);
        }
      };

    }, [history, opportunityID]);

    return (
      <div className="w-100">
        <lf-opportunity ref={ref} opportunity={opportunityID}></lf-opportunity>
      </div>
    )
  };

export default ResiSubmissionWebComponent;
