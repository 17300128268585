
import initialState from './initialState';

import * as types from '../constants/types';

import Storage from '../helpers/storage';

const objectAssign = require('object-assign');
const Moment = require('moment');


export default function applicationsReducer(state = initialState.applications, action) {
  switch (action.type) {
    case types.GET_PARTIAL_APPLICATIONS: {
      return objectAssign({}, state, { partial: action.list, partialAll: action.list });
    }

    case types.GET_PARTIAL_MOTOR_APPLICATIONS_START: {
      return objectAssign({}, state, { partialMotor: objectAssign({}, state.partialMotor, { requestId: action.requestId }) },
        { partialMotorAll: objectAssign({}, state.partialMotorAll, { requestId: action.requestId }) });
    }

    case types.GET_PARTIAL_MOTOR_APPLICATIONS_COMPLETE: {
      return state.partialMotor.requestId !== action.requestId
        ? state
        : objectAssign({}, state, { partialMotor: objectAssign({}, state.partialMotor, { resultRequestId: action.requestId, results: action.list }) },
          { partialMotorAll: objectAssign({}, state.partialMotorAll, { resultRequestId: action.requestId, results: action.list }) });
    }

    case types.GET_PARTIAL_MOTOR_APPLICATIONS_ERROR: {
      return state.partialMotor.requestId !== action.requestId
        ? state
        : objectAssign({}, state, { partialMotor: objectAssign({}, state.partialMotor, { errorRequestId: action.requestId, error: action.error }) },
          { partialMotorAll: objectAssign({}, state.partialMotorAll, { errorRequestId: action.requestId, error: action.error }) });
    }

    case types.GET_APPLICATIONS: {
      const list = sort(state.order, filter(state.filter, action.list));
      const other = action.list.length - list.length;

      return objectAssign({}, state, {
        all: action.list, list, query: '', other,
      });
    }

    case types.GET_APPLICATIONS_AND_OPPORTUNITIES: {
      const list = sort(state.order, filter(state.filter, action.applications));
      const other = action.applications.length - list.length;
      return objectAssign({}, state, {
        all: action.applications,
        list,
        query: '',
        other,
      });
    }

    case types.GET_APPLICATION: {
      const active = find(state.all, action.applicationID) ? JSON.parse(JSON.stringify(find(state.all, action.applicationID))) : undefined;
      const documents = JSON.parse(Storage.getItem('documents') || '{}');

      if (active) {
        if (active.publishDocuments) {
          active.publishDocuments = active.publishDocuments.map((doc) => {
            doc.isNew = !documents[doc.id];
            return doc;
          });
        }
      }

      return objectAssign({}, state, { active });
    }

    case types.SAVE_APPLICATION: {
      return state;
    }

    case types.POLL_APPLICATION: {
      return state;
    }

    case types.UPDATE_APPLICATION: {
      const active = objectAssign({}, state.active, { loanAssessmentResult: action.result });
      return objectAssign({}, state, { active });
    }

    case types.COMPLETE_UPLOAD: {
      const active = JSON.parse(JSON.stringify(state.active));
      active.actions.map((a) => {
        if (a.id == action.action.id) {
          a.status = 2;

          if (!a.history) {
            a.history = [];
          }

          a.history.push({
            timestamp: Moment.utc().format(),
            type: 'upload',
            fileName: action.file.name,
            comment: null,
          });
        }
      });
      return objectAssign({}, state, { active });
    }

    case types.COMPLETE_ACTION: {
      const active = JSON.parse(JSON.stringify(state.active));

      active.actions.map((a) => {
        if (a.id == action.action.id) {
          a.status = 2;

          if (!a.history) {
            a.history = [];
          }

          a.history.push({
            timestamp: Moment.utc().format(),
            type: 'action',
            filename: null,
            comment: null,
          });
        }
      });

      return objectAssign({}, state, { active });
    }

    case types.FILTER_APPLICATIONS: {
      const list = sort(state.order, filter(action.filter, state.all));
      const other = state.all.length - list.length;

      const preference = JSON.parse(Storage.getItem('preference') || '{}');
      preference.filter = action.filter;

      Storage.setItem('preference', JSON.stringify(preference));

      return objectAssign({}, state, { filter: action.filter, list, other });
    }

    case types.SORT_APPLICATIONS: {
      const list = action.order != state.order ? sort(action.order, filter(state.filter, state.all)) : state.list;
      const other = state.all.length - list.length;

      const preference = JSON.parse(Storage.getItem('preference') || '{}');
      preference.order = action.order;

      Storage.setItem('preference', JSON.stringify(preference));

      return objectAssign({}, state, { order: action.order, list, other });
    }

    case types.SEARCH_APPLICATIONS: {
      const list = sort(state.order, search(action.query, filter(state.filter, state.all)));
      const other = search(action.query, state.all).length - list.length;

      // search partial applications
      const partial = searchPartialApplications(action.query, state.partialAll);

      // search partial motor applications
      const partialMotorResults = searchPartialMotorApplications(action.query, state.partialMotorAll.results);

      return objectAssign({}, state, {
        query: action.query, list, other, partial, partialMotor: objectAssign({}, state.partialMotor, { results: partialMotorResults }),
      });
    }

    case types.SET_APPLICANT_PROPERTY: {
      const copy = JSON.parse(JSON.stringify(state.all));

      const all = copy.map((application) => {
        if (action.data.applicationNumber == application.applicationNumber) {
          application.applicants.map((applicant) => {
            if (action.data.applicantId == applicant.applicantId) {
              applicant.contact[action.data.field][0] = action.data.value;
            }
            return applicant;
          });
        }
        return application;
      });

      const active = find(copy, action.data.applicationNumber);

      return objectAssign({}, state, { all, active });
    }

    default:
      return state;
  }
}

const find = (list, id) => {
  const result = list.filter((application) => parseInt(application.applicationNumber) == parseInt(id));

  return result.length ? result[0] : undefined;
};

const filter = (_filter, list) => list.filter((application) => _filter === 2 || _filter === application.applicationState);

const sort = (order, list) => {
  let res;
  switch (order) {
    case 'submitted_asc':
    case 'submitted_desc': {
      res = list.sort((a, b) => (Moment(a.applicationDate).format('x') <= Moment(b.applicationDate).format('x') ? 1 : -1));
      break;
    }

    case 'status_asc':
    case 'status_desc': {
      res = list.sort((a, b) => (a.applicationStatus.code <= b.applicationStatus.code ? -1 : 1));
      break;
    }

    case 'actions_asc':
    case 'actions_desc': {
      res = list.sort((a, b) => {
        let y = a.actions.length;
        let z = b.actions.length;

        if (a.applicationStatus.message && a.applicationStatus.message.introducerAction) y++;
        if (b.applicationStatus.message && b.applicationStatus.message.introducerAction) z++;

        if (a.applicationStatus.message && !a.applicationStatus.message.introducerAction) y = 0;
        if (b.applicationStatus.message && !b.applicationStatus.message.introducerAction) z = 0;

        return y <= z ? -1 : 1;
      });
      break;
    }

    case 'modified_asc':
    case 'modified_desc': {
      res = list.sort((a, b) => (Moment(a.lastUpdatedDate).format('x') <= Moment(b.lastUpdatedDate).format('x') ? -1 : 1));
      break;
    }

    case 'name_asc':
    case 'name_desc': {
      res = list.sort((a, b) => {
        const y = a.applicants[0] && a.applicants[0].name.split(' ');
        const z = b.applicants[0] && b.applicants[0].name.split(' ');

        return (y[y.length - 1] || '').toLowerCase() <= (z[z.length - 1] || '').toLowerCase() ? 1 : -1;
      });
      break;
    }

    default: {
      res = list;
      break;
    }
  }

  if (order.search('desc')) {
    res.reverse();
  }

  return res;
};

const search = (query, list) => {
  if (!query) {
    return list;
  }

  const regExp = new RegExp(query.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');

  return list.filter((application) => {
    if (application.applicationNumber.toString().search(regExp) !== -1) {
      return true;
    }

    if (application.isOpportunity && application.displayName && application.displayName.search(regExp) !== -1) {
      return true;
    }
    const applicants = application.applicants.filter((applicant) => {
      if (applicant.name && applicant.name.search(regExp) !== -1) {
        return true;
      }
    });

    if (applicants.length) {
      return true;
    }
  });
};

const searchPartialApplications = (query, partialApplicationList) => {
  if (!query || !partialApplicationList) {
    return partialApplicationList;
  }

  const regExp = new RegExp(query.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');

  return partialApplicationList.filter((application) => {
    if (application.applicationName.toString().search(regExp) !== -1) {
      return true;
    }

    if (application.isOpportunity && application.displayName && application.displayName.search(regExp) !== -1) {
      return true;
    }
    const applicants = application.applicants.individuals.filter((applicant) => {
      if ((applicant.firstName && applicant.firstName.search(regExp) !== -1)
        || (applicant.middleName && applicant.middleName.search(regExp) !== -1)
        || (applicant.lastName && applicant.lastName.search(regExp) !== -1)) {
        return true;
      }
    });

    if (applicants.length) {
      return true;
    }
  });
};

const searchPartialMotorApplications = (query, partialMotorApplicationList) => {
  if (!query || !partialMotorApplicationList) {
    return partialMotorApplicationList;
  }

  const regExp = new RegExp(query.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');

  return partialMotorApplicationList.filter((application) => {
    if (application.displayName.toString().search(regExp) !== -1) {
      return true;
    }
  });
};
