import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Money } from '../../utils';
import ApplicationActionIcon from './applicationActionIcon';
import ApplicationTypeIcon from './applicationTypeIcon';

const OpportunitiesListItem = (props) => (
  <Link className="loan-item" to={`/opportunities/${props.opportunity.applicationNumber}`}>
    <div className={`${!props.applicationState ? '' : ''}`}>
      {props.opportunity.displayName}
      <ApplicationTypeIcon type={props.opportunity.applicationType} />
      <Money className="loan-amount pull-right">{props.opportunity.amount}</Money>
      <p className="loan-status">{props.opportunity.applicationStatus.description}</p>
    </div>
    {/* opportunity.actions needs to finalised */}
    <ApplicationActionIcon status={props.opportunity.applicationStatus} actions={(props.opportunity.applicationState ? props.opportunity.actions : [])} />
  </Link>
);

OpportunitiesListItem.propTypes = {
  opportunity: PropTypes.object.isRequired,
};

export default OpportunitiesListItem;
