import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const ApplicationSubmitPane = (props) => {
  if (!props.visible) {
    return null;
  }

  // TODO: revisit this to start a new application 
  const opportunityID = ''; // "63162477-c582-8ad1-ec79-6d59628e8436";

  return (
    <div className="loan-submit">
      <h4>Whoohoo</h4>

      <p className="text-lg white mute mb-lg">
      We&apos;re making submitting applications to us as fast and fuss-free as possible and you&apos;ve got early access! There are still some conditions.
      </p>

      <div className="btn-toolbar">
        { props.showResApplicationButton && !props.showMotorApplicationButton && <Link to={`/Opportunities/${opportunityID}`} className="btn btn-lg btn-primary">Submit application</Link> }
        { props.showResApplicationButton && props.showMotorApplicationButton && <Link to={`/Opportunities/${opportunityID}`} className="btn btn-lg btn-primary">Submit Residential application</Link> }
        { props.showMotorApplicationButton && <button className="btn btn-lg btn-primary" onClick={props.onSubmitMotorClick}>Submit Motor application</button> }
      </div>

    </div>
  );
};

ApplicationSubmitPane.propTypes = {
  visible: PropTypes.bool.isRequired,
  showResApplicationButton: PropTypes.bool.isRequired,
  showMotorApplicationButton: PropTypes.bool.isRequired,
  onSubmitMotorClick: PropTypes.func.isRequired,
};

export default ApplicationSubmitPane;
