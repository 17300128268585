import { v4 as uuidv4 } from 'uuid';
import API from '../api';
import * as types from '../constants/types';


export const filterApplications = (filter) => ({
  type: types.FILTER_APPLICATIONS,
  filter,
});

export const sortApplications = (order) => ({
  type: types.SORT_APPLICATIONS,
  order,
});


export const searchApplications = (query) => ({
  type: types.SEARCH_APPLICATIONS,
  query,
});

export const getApplications = () => (dispatch) => API.getApplications()
  .then((response) => dispatch({
    type: types.GET_APPLICATIONS,
    list: response.data,
  }));

export const getPartialMotorApplications = () => (dispatch) => {
  const requestId = uuidv4();
  dispatch({
    type: types.GET_PARTIAL_MOTOR_APPLICATIONS_START,
    requestId,
  });
  return API.getPartialMotorApplications()
    .then((response) => dispatch({
      type: types.GET_PARTIAL_MOTOR_APPLICATIONS_COMPLETE,
      list: response.data,
      requestId,
    }), (error) => dispatch({
      type: types.GET_PARTIAL_MOTOR_APPLICATIONS_ERROR,
      error,
      requestId,
    }));
};

export const getApplicationsAndOpportunities = (session) => (dispatch) => Promise.allSettled([
  API.getApplications(),
  API.getOpportunities(session),
]).then((results) => {
  const applicationsResponse = results[0];
  const opportunitiesResponse = results[1];

  let applicationsList = [];
  let opportunitiesList = [];

  if (applicationsResponse.status === PromiseSettlementStatus.FULFILLED) {
    applicationsList = applicationsResponse.value.data;
  }
  if (opportunitiesResponse && opportunitiesResponse.status === PromiseSettlementStatus.FULFILLED) {
    opportunitiesList = opportunitiesResponse.value.data;
  }

  // Transform opportunities to match the application structure
  const mappedOpportunities = mapOpportunitiesToApplications(opportunitiesList);
  const allApplications = [...applicationsList, ...mappedOpportunities];

  dispatch({
    type: types.GET_APPLICATIONS_AND_OPPORTUNITIES,
    all: allApplications,
    applications: allApplications,
    list: allApplications,
  });
}).catch((error) => {
  throw error;
});

function mapOpportunitiesToApplications(opportunities) {
  // some of the fields need to be revisited when mapping to application
  return opportunities.map((opportunity) => ({
    amount: opportunity.estimatedAmount || 0,
    applicationNumber: opportunity.id,
    lastUpdatedDate: opportunity.createdDate,
    applicationDate: opportunity.createdDate,
    applicationType: 1, // residential
    applicants: !opportunity.borrowers ? [{ name: '' }] : opportunity.borrowers.split(',').map((borrower) => ({ name: borrower.trim() })),
    applicationStatus: ({ description: opportunity.status, code: 100 }), // opportunity.status is 'Pre-submission', code 100
    applicationState: 1, // setting to 1 so opportunities show up in the list when the filter is set to 'All' or 'Current Applications'
    displayName: opportunity.name,
    description: opportunity.name || 'No description available',
    actions: [],
    isOpportunity: true,
  }));
}

const PromiseSettlementStatus = Object.freeze({
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
});

export const getApplication = (applicationID) => ({
  type: types.GET_APPLICATION,
  applicationID,
});

export const updateApplication = (result) => ({
  type: types.UPDATE_APPLICATION,
  result,
});

export const saveApplication = (data) => (dispatch) => API.saveApplication(data)
  .then(() => dispatch({
    type: types.SAVE_APPLICATION,
  }));

export const pollApplication = (applicationNumber, attempt) => (dispatch) => API.pollApplication(applicationNumber, attempt)
  .then((response) => dispatch({
    type: types.POLL_APPLICATION,
    response,
  }));


export const completeUpload = (action, file) => ({
  type: types.COMPLETE_UPLOAD,
  action,
  file,
});
export const completeAction = (applicationNumber, action) => (dispatch) => API.setActionComplete(applicationNumber, action.id)
  .then(() => dispatch({
    type: types.COMPLETE_ACTION,
    action,
  }));

export const setApplicantProperty = (data) => (dispatch) => {
  dispatch({
    type: types.SET_APPLICANT_PROPERTY,
    data,
  });

  return API.setApplicantProperty(data);
};
